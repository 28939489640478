import { Button, Typography } from '@mui/material';
import backgroundLearningBanner from 'assets/home/background-banner-academy.png';
import {
  BANNER_BREAKING_POINT,
  BANNER_HIDE_BREAKING_POINT
} from 'features/homePage/HomeBannerLayout';
import FormattedMessage from 'features/i18n/FormattedMessage';
import styled from 'styled-components';
import useTr from 'utils/hooks/useTr';

export const AcademyBanner = () => {
  const translate = useTr();

  const handleClick = () => {
    window.open(translate('layout.sidebar.actions.notifications.academy.link'), '_blank');
  };

  return (
    <Root onClick={handleClick}>
      <ImageContainer>
        <Image src={backgroundLearningBanner} alt="background" />
      </ImageContainer>
      <Body>
        <HeaderText>
          <FormattedMessage id="home.academy_banner.title" />
        </HeaderText>
        <BodyText>
          <FormattedMessage id="home.academy_banner.subtitle" />
        </BodyText>
        <div>
          <StyledButton size="small" variant="contained">
            <FormattedMessage id="home.academy_banner.button" />
          </StyledButton>
        </div>
      </Body>
    </Root>
  );
};

const StyledButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  color: ${({ theme }) => theme.colors.commonBlackMain};
  border-radius: ${({ theme }) => theme.borderRadius.one};

  &:hover {
    background-color: ${({ theme }) => theme.colors.commonWhiteMain};
  }
`;

const Root = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius.one};
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 200px;
  cursor: pointer;

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }

  @media (max-width: ${BANNER_HIDE_BREAKING_POINT}px) {
    display: none;
  }
`;

const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  object-fit: contain;
`;

const Body = styled.div`
  padding-left: ${({ theme }) => theme.spacings.six};

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.spacings.two};

  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    gap: ${({ theme }) => theme.spacings.one};
  }
`;

const HeaderText = styled(Typography).attrs({
  variant: 'h4',
  color: 'white'
})`
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.h5
    })}
  }
`;

const BodyText = styled(Typography).attrs({
  variant: 'body1',
  color: 'white'
})`
  max-width: 500px;
  @media (max-width: ${BANNER_BREAKING_POINT}px) {
    ${({ theme }) => ({
      ...theme.typography.body2
    })}
  }
`;
