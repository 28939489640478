import { getRoutePath } from 'config/routes';
import { SigninRedirectArgs, User, UserManager } from 'oidc-client-ts';

const metadata = {
  authority: process.env.REACT_APP_OIDC_BASE_URL || '',
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID || '',
  grant_types_supported: ['refresh_token'],
  scope: 'openid email profile'
};

class AuthService {
  private oidcManager: UserManager;
  private user: User | null = null;

  constructor() {
    this.oidcManager = new UserManager({
      ...metadata,
      redirect_uri: window.origin + getRoutePath('loginCallback')
    });
  }

  /**
   * Should OIDC auth be enabled at all?
   */
  public isEnabled(): boolean {
    return true;
  }

  /**
   * Should OIDC be enabled globally?
   * Otherwise, only allow it as an alternative.
   */
  public isGlobal(): boolean {
    return false;
  }

  public async initialize(): Promise<boolean> {
    const user = await this.oidcManager.getUser();
    if (user) {
      this.user = user;
    }
    return true;
  }

  public async loginRedirect(params: SigninRedirectArgs): Promise<void> {
    params['prompt'] = 'select_account';
    return this.oidcManager.signinRedirect(params);
  }

  public async loginCallback(): Promise<boolean> {
    try {
      this.user = await this.oidcManager.signinRedirectCallback();
    } catch (e) {
      this.user = null;
    }
    return this.user !== null;
  }

  public isAuthenticated(): boolean {
    return !!this.user;
  }

  public getAccessToken(): string {
    if (this.user === null || this.user.access_token === null) {
      throw new Error('Missing JWT access token');
    }
    return this.user.access_token;
  }

  public getAuthorizationHeaders(mustExist = false): object {
    if (!this.isAuthenticated()) {
      if (mustExist) {
        throw new Error('Missing JWT access token');
      }
      return {};
    }
    return {
      Authorization: 'Bearer ' + this.getAccessToken()
    };
  }

  public logout() {
    this.user = null;
    try {
      this.oidcManager.signoutRedirect();
    } catch (e) {
      /* empty */
    }
  }

  public clearCredentials() {
    this.oidcManager.removeUser();
    this.user = null;
  }
}

export const authService = new AuthService();
