import 'focus-visible';
import 'styles/index.scss';

import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { AppLoader } from 'components/AppLoader';
import AuthWrapper from 'components/auth/AuthWrapper';
import InitializationProvider from 'components/InitializationProvider';
import { ModalProvider, RootModal } from 'components/modals';
import { StyledToastContainer } from 'components/toasts/StyledToastContainer';
import { getRoutes } from 'config/routerRoutes';
import { AppRoutes } from 'config/routes';
import { getAudiences } from 'features/audiences/store/slice';
import { ChurnKeyLoader } from 'features/churnKey/ChurnKeyLoader';
import { useChurnKeyPauseWall } from 'features/churnKey/useChurnKeyPauseWall';
import { removeCurrentUserQueries } from 'features/currentUser/currentUserQueries';
import { getCurrentUser, setAuthReady, setHasJwtToken } from 'features/customer/store/actions';
import {
  getAuthReady,
  getCustomerRole,
  getCustomerTeams,
  getHasToken,
  getIsApiCustomer,
  getIsAuthenticated
} from 'features/customer/store/selectors';
import { getEmbeddingModels } from 'features/embeddingModels/store/slice';
import { TrialModal } from 'features/forceTrial/TrialModal';
import { GlobalConfetti } from 'features/global-confetti/GlobalConfetti';
import { getGroup } from 'features/group/store/actions';
import { HelpscoutBeaconLoader } from 'features/helpscout/HelpscoutBeaconLoader';
import { Intl } from 'features/language/Intl';
import { getCurrentLanguage } from 'features/language/store/selectors';
import { LanguageToolWidget } from 'features/languageToolIntegration/LanguageToolWidget';
import { MaintenanceGuard } from 'features/maintenance/MaintenanceGuard';
import { CustomPostHogProvider } from 'features/posthog/CustomPostHogProvider';
import { TrialExpiryReminderModalTrigger } from 'features/pricing/TrialExpiryReminderModalTrigger';
import { ErrorBoundary } from 'features/react-error-boundary/ErrorBoundary';
import { useFetchHasCustomerTeam } from 'features/services/hooks/useFetchHasCustomerTeam';
import useFetchSubscriptionInBackground from 'features/services/hooks/useFetchSubscriptionInBackground';
import { TeamSubscriptionCanceledWarning } from 'features/team/TeamSubscriptionCanceledWarning';
import { useAdcellTracking } from 'features/tracking/adcell/hooks/useAdcellTracking';
import { SegmentLoader } from 'features/tracking/segment/SegmentLoader';
import { UserNotificationsManager } from 'features/userNotifications/UserNotificationsManager';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Slide } from 'react-toastify';
import { authService } from 'services/auth/AuthService';
import { BackofficePusherProvider } from 'services/backofficeIntegration/BackofficePusherProvider';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { newMaterialTheme } from 'styles/new-theme/newMaterialTheme';
import { NewThemeConfig } from 'styles/new-theme/newThemeConfig';

/*
 * Authentication flow: user opens the app, if he doesn't have any data in localStorage
 * (particularly apiToken), he will be redirected to login page, so there's no reason to
 * do any additional requests.
 *
 * If he does (userHasToken = true), then we're checking if the token is valid.
 * Only then isAuthenticated is true, and we execute further requests
 * (e.g. getTeam and others in other components) that require API tokens.
 */

function useClearUserDataAfterLogout() {
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  useEffect(() => {
    if (!isAuthenticated) {
      /**
       * In previous implementation we were invalidating queries during the
       * logout saga instead of removing them here.
       * That solution had two issues:
       * 1) invalidation took place before the components where unmounted so
       * react-query tried to refetch the data - for this reason logout saga
       * is a bad moment to perform that action
       * 2) when data is invalidated it stays in cache and can be reused before
       * new data is fetched, so user "B" could see data of user "A" as long as
       * his own data was not fetched - for this reason we have to remove data
       * instead of invalidating it
       */
      removeCurrentUserQueries();
    }
  }, [isAuthenticated]);
}

type Props = {
  themeMode: 'light' | 'dark';
  themeConfig: NewThemeConfig;
};

const App = ({ themeMode, themeConfig }: Props) => {
  const dispatch = useAppDispatch();
  const authReady = useAppSelector(getAuthReady);
  const userHasToken = useAppSelector(getHasToken);
  const isAuthenticated = useAppSelector(getIsAuthenticated);
  const customerGroup = useAppSelector(state => state.customer.groupId);
  const isCustomerLoaded = useAppSelector(state => state.customer.isCustomerDataLoaded);
  const userRole = useAppSelector(getCustomerRole);
  const isApiCustomer = useAppSelector(getIsApiCustomer);
  const customerTeams = useAppSelector(getCustomerTeams);
  const [routes, setRoutes] = useState(() =>
    getRoutes(customerGroup, userRole, isApiCustomer, customerTeams)
  );
  const appLanguage = useAppSelector(getCurrentLanguage);

  useAdcellTracking();
  useFetchSubscriptionInBackground();
  useClearUserDataAfterLogout();
  useChurnKeyPauseWall();
  useFetchHasCustomerTeam();

  useEffect(() => {
    if (authReady) {
      return;
    }

    if (authService.isEnabled()) {
      authService.initialize().then(() => {
        dispatch(setHasJwtToken(authService.isAuthenticated()));
        dispatch(setAuthReady());
        if (authService.isAuthenticated()) {
          dispatch(getCurrentUser.request());
        }
      });
    } else {
      dispatch(setAuthReady());
    }
  }, [dispatch, authReady]);

  useEffect(() => {
    if (userHasToken) {
      dispatch(getCurrentUser.request());
    }
  }, [dispatch, userHasToken]);

  useEffect(() => {
    if (isAuthenticated && isCustomerLoaded) {
      dispatch(getEmbeddingModels.request());
      dispatch(getGroup.request());
      dispatch(getAudiences.request());
    }
  }, [dispatch, isAuthenticated, isCustomerLoaded]);

  useEffect(() => {
    setRoutes(getRoutes(customerGroup, userRole, isApiCustomer, customerTeams));
  }, [customerGroup, customerTeams, isApiCustomer, userRole]);

  const muiTheme = newMaterialTheme(appLanguage, themeMode, themeConfig);

  return (
    <StyledEngineProvider injectFirst={true}>
      <ThemeProvider theme={muiTheme}>
        <CssBaseline>
          <Intl>
            <ErrorBoundary>
              <Router>
                <AppLoader>
                  <ModalProvider>
                    <MaintenanceGuard>
                      <CustomPostHogProvider>
                        <AuthWrapper>
                          <BackofficePusherProvider>
                            <UserNotificationsManager />
                            <InitializationProvider>
                              <AppRoutes>{routes}</AppRoutes>
                              <TrialModal />
                              <RootModal />
                              <TeamSubscriptionCanceledWarning />
                              <TrialExpiryReminderModalTrigger />
                              <LanguageToolWidget />
                              <HelpscoutBeaconLoader />
                              <ChurnKeyLoader />
                              <SegmentLoader />
                              <GlobalConfetti />
                            </InitializationProvider>
                          </BackofficePusherProvider>
                        </AuthWrapper>
                      </CustomPostHogProvider>
                    </MaintenanceGuard>
                  </ModalProvider>
                </AppLoader>
                {/*
                  It was necessary to move the ToastProvider here
                  so that Toast could appear at forced logout action.
                  With previous implementation, after user was
                  forced to logout and redirected, the ToastProvider
                  was remounted and the toast was lost

                  Also ToastContainer must be wrapped with Router because
                  we are using internal links in toasts, e.g:
                  https://bitbucket.org/neuroflash/neuroflash_app/src/4ff4a657091fd93e02368a0d6e3ad40a88824009/src/services/api/utils/mkHandleErrorsWithHyperlink.tsx#mkHandleErrorsWithHyperlink.tsx-18:18
                */}
                <StyledToastContainer
                  position="bottom-right"
                  autoClose={3000}
                  closeButton={false}
                  closeOnClick
                  hideProgressBar
                  transition={Slide}
                />
              </Router>
            </ErrorBoundary>
          </Intl>
        </CssBaseline>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
