import LocalStorageKey from 'config/localStorageKey';
import { authService } from 'services/auth/AuthService';

export function apiTokenHeader(required = true) {
  if (authService.isEnabled() && authService.isAuthenticated()) {
    return authService.getAuthorizationHeaders(required);
  } else {
    const token = localStorage.getItem(LocalStorageKey.APIToken);

    if (token) {
      return {
        'X-API-TOKEN': token
      };
    }
  }

  if (required) {
    throw new Error('Missing backoffice api token');
  }
}
